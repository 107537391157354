<template>
  <form
    v-bind="$attrs"
    :id="uuid + '_validationform'"
    @submit.prevent="submit"
  >
    <div
      :class="uuid + '_validationformerrorcontainer'"
      class="pure-u-1"
    >
      <ErrorMessage :error-message="errorMessage" />
    </div>
    <slot />
  </form>
</template>

<script>
import ErrorMessage from '~/components/input-components/ErrorMessage'

export default {
  components: {ErrorMessage},
  props: {
    rules: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    errorContainer: {
      type: String,
      required: false,
      default: (props) => props.uuid + '_validationformerrorcontainer'
    },
    getForm: {
      type: Function,
      required: true,
      default: null
    },
    useModelValuesForValidation: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      errorMessage: null
    }
  },
  methods: {
    getFormFields(fields) {
      if (!fields) {
        return this.getFormFields([...this.$slots.default()])
      }
      const array = Array.isArray(fields) ? fields : [fields]
      const values = typeof this.getForm === 'function' ? this.getForm() : this.getForm
      return array.reduce((acc, value) => {
        if (value.ref?.r?.startsWith('validationForm')) {
          let field = value.ctx.refs[value.ref?.r]?.length > 0 ? value.ctx.refs[value.ref?.r][0] : value.ctx.refs[value.ref?.r]
          acc.push({...field, value: this.getValue(value, values), name: value.props?.name})
        }

        if (value.children) {
          acc = acc.concat(this.getFormFields(value.children))
        }
        return acc
      }, [])
    },
    getFormValues() {
      const fields = this.getFormFields()
      return fields.reduce((o, f) => ({...o, [f.name]: f.value}), {})
    },
    submit: async function () {
      if (await this.validate()) {
        this.$emit('success')
        this.$el.dispatchEvent(new Event('captureForm'))
      }
    },
    validate: async function () {
      this.errorMessage = null
      // field rule validation
      let isValid = await this.getFormFields().reduce(async (valid, field) => {
        let value = this.useModelValuesForValidation ? field.customAttrs?.modelValue ?? field.value : field.value
        return (await field.validate(value)) && valid
      }, true)
      // form rule validation
      if (this.$props.rules && this.$props.rules.length > 0) {
        let formValid = true
        for (const formRule of this.$props.rules) {
          if (!(await formRule.validator(this.getFormValues()))) {
            this.errorMessage = this.$tv({messageKey: formRule.message.key, messageValues: formRule.message.options})
            formValid = false
            break
          }
        }

        isValid = isValid && formValid
      }
      return isValid
    },
    getValue(object, values) {
      const valueName = object.props.name
      return values[valueName]
    }
  },
  expose: ['validate']
}
</script>
